html,
body,
#app {
  width: 100%;
  height: 100%;
  font-size: 13px;
  font-family: 'Microsoft YaHei', '-apple-system', BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.drag {
  -webkit-app-region: drag;
}

ul,
ol {
  list-style: none;
}

.no-drag {
  -webkit-app-region: no-drag;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pointer {
  cursor: pointer;
}

.not-select {
  user-select: none;
}

::-webkit-scrollbar {
  width: 2px;
  height: 0px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 2px;
  width: 2px;
}

.ant-message {
  color: #fff;
  .sg-message-container {
    &.login-message {
      width: 460px;
      position: absolute;
      top: 0;
      left: 0;
    }
    .ant-message-notice-content {
      padding: 11px 16px;
      background: #2c2e34;
      border-radius: 8px;
      font-size: 14px;
      box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.4);
      .ant-message-custom-content {
        display: flex;
        align-items: center;
        .true-img {
          width: 100%;
          height: auto;
        }
        span {
          margin-left: 13px;
          color: #c6c6c6;
        }
      }
    }
    &.sg-message-container-success {
      .ant-message-notice-content {
        border: 1px solid #208051;
        background: #14231C;
        span {
          color: #FFFFFF;
        }
      }
    }
    &.sg-message-container-warn {
      .ant-message-notice-content {
        border: 1px solid #7A333A;
        background: #1D0F10;
        span {
          color: #c6c6c6;
        }
      }
    }
  }
}
